import { cvInterface } from './types'

//BAckground is in Key skills for some reason ???

export const emilyData: cvInterface = {
  palette: {
    primary: '#5555C5',
    secondary: '#FFC759',
    third: '#f76c5e',
    cardColor: '#E5E5E5',
  },
  phoneNumber: null,
  firstName: 'Emily',
  secondName: 'Donaldson',
  tagline: 'Seeking a role in Adventure',
  city: 'Poplar',
  county: 'London',
  email: 'emilymaydonaldson@hotmail.com',
  welcome: 'Hi,',
  intro:
    "My name is Emily Donaldson and welcome to my CV website.  Here you can find out about my career history, education and life experiences that make me a great employee. There is a downloadable version of my CV attached below. If you feel my experience makes me suitable for a role you're looking to fill. Please feel free to contact me via email.",

  cv: {
    summary:
      "Childcare professional with specialized training in adventure sports. 2+ years of nursery experience and 3+ years of leading and teaching adventure sports. Excited by the idea of teaching children and people new skills. Takes pride in maintaining and cultivating welcoming learning environments and beautiful spaces which brighten people's days. Encouraging children to push their abilities, out of their comfort zone whilst always ensuring they are safe. Organizational and logistical expert. ",
    workExperience: [
      {
        jobTitle: 'Group Leader',
        companyName: 'Camp Beaumont',
        location: "St Augustine's Priory, Ealing",
        startDate: new Date('2020-03-01'),
        endDate: new Date(),
        description:
          'Teaching, minding and working with children (aged three to four). My daily responsibilities involve: Organising, preparing and leading fun learning activities for children in bubbles of five to twenty. Some of my favourite activities  Cleaning and preperation of equipment as to comply with the latest covid 19 requirements. Making sure all children are happy, safe and maximising their learning experience. I most enjoy encoraging children to come out of their shell by using fun activities and games as ice breakers, some of my favourite games are dinosaur discoveries, cool detextives and consturction challenges.',
      },
      {
        jobTitle: 'Instructor',
        companyName: 'MooCanoes',
        location: 'Limehouse Basin - London',
        startDate: new Date('2018-06-01'),
        endDate: new Date('2018-10-01'),
        description:
          'During the busy summer I was in charge of setting up, inducting and overseeing up to 25 kayaking and paddleboarding guests per day. I was also involved with other water disciplines from canoeing, tandem kayaking and paddle boarding. It was my responsibility to ensure everyone, including the occasional dog, was geared up with buoyancy aids. I also gave small briefings on the basics of water safety such as how to use a paddle when on the water and what to do in case of a fall in. Above all I made sure everyone was having a fun time on the water in a safe environment.',
      },
      {
        jobTitle: 'Nursery Assistant',
        companyName: 'Lanterns Art',
        location: 'Central London',
        startDate: new Date('2018-05'),
        endDate: new Date('2018-06'),
        description:
          'Caring for and teaching children aged one to two. We focused on teaching basic topics such as the alphabet, colours, counting and identifying different shapes. This role required good communication and people skills as it was my responsibility to work face to face with both the parents and children with daily verbal reports to the parents on what their child has been up to whilst they were away. ',
      },
      {
        jobTitle: 'Logistics Driver',
        companyName: 'Royal Air Force ',
        location: 'Defence School of Transport',
        startDate: new Date('2015-10'),
        endDate: new Date('2017-10'),
        description:
          'Driver for the Royal Air Force. I passed through basic training and got my driving licence during this period. I had all the same responsibly as any other solider. I learnt a lot on topics such as weapon handling, performing in parades and maintaining my fitness. It pushed my ability to organise myself under strict time pressures and to keep myself and my teammates safe and secure. My favourite time serving was during a training-exercise called blue warrior which tested my survival and combat abilities during the middle of winter.',
      },
      {
        jobTitle: 'Instructor',
        companyName: 'Peak Pursuits ',
        location: 'Audley, Stoke-on-Trent',
        startDate: new Date('2015-10'),
        endDate: new Date('2014-06'),
        description:
          'Water Instructor. I organised and ran team building sessions for both schools and businesses. Activities including rock climbing, raft building and orienteering. I’m often leading events such as children’s parties and stag dos. I would occasionally server at the café and make bookings over the phone. I also assisted with site security locking up at the end of the day and handling transactions for the business. Health and safety played an important part of my role and I would often perform risk assessments on areas of the site.',
      },
    ],
    education: [
      {
        name: 'Alsager High School',
        startDate: new Date('2008-09-10'),
        endDate: new Date('2012-09-10'),
        qualifications: [
          { name: 'Maths', type: 'GCSE', grade: 'C' },
          { name: 'English', type: 'GCSE', grade: 'C' },
          { name: 'ICT', type: 'BTEC', grade: 'Pass' },
          { name: 'Sports', type: 'BTEC', grade: 'Merit' },
          { name: 'Science', type: 'BTEC', grade: 'Pass' },
        ],
      },
      {
        name: 'Reaseheath College, Nantwich',
        startDate: new Date('2013'),
        endDate: new Date('2015'),
        qualifications: [
          {
            name: 'Safeguarding in a Learning Environment',
            type: 'NCFE',
            grade: 'Level 1',
          },
          {
            name: 'Diploma in Sports ',
            type: 'BTEC Level 2 (QCF)',
            grade: 'Distinction*',
          },
          {
            name: 'Subsidiary Diploma in Sports',
            type: 'BTEC Level 3 (QCF)',
            grade: 'Merit',
          },
          {
            name: 'Outdoor Activity Leadership',
            type: 'NCFE Level 2',
            grade: 'Merit',
          },
          {
            name: ' Diploma in SPORTS  with ',
            type: 'BTEC Level 3 (QCF)',
            grade: 'Double Distinction',
          },
        ],
      },
    ],
    skillsIntro:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas dolor velit, vulputate non erat eu, molestie facilisis lectus. Donec consectetur sapien lectus, et luctus augue sagittis sed. Quisque sed vestibulum nibh, sit amet posuere risus. Vestibulum ut metus eu enim sagittis faucibus. Ut accumsan dui nisl, et rhoncus purus euismod vitae. Aliquam pulvinar vulputate velit non tristique. Nullam elementum finibus aliquam. Maecenas sollicitudin tincidunt porta. Sed pellentesque nisi ac viverra iaculis. ',
    skills: [
      {
        name: 'Teaching',
        description:
          'I love helping children reach their full potential through the art of teaching. I have expereince teaching children ages x to y.',
        experience: '3 years',
        icon: 'lecture',
      },
      {
        name: 'Care',
        description:
          'I find caring others very fullfilling. A large part of teaching children is ensuring that their needs are met and they are all happy and safe.',
        experience: '3 years',
        icon: 'care',
      },
      {
        name: 'Logistics',
        description:
          'Some people have described me as an organisatoinal machine! I get huge amounts of satisfaction in creating and organising spaces that brighten peoples days.',
        experience: '3 years',
        icon: 'inventory',
      },
      {
        name: 'Water Sports',
        description:
          'I have professional experience teaching adults and children various water spots. Some of my favorites include kyakaing and paddel boarding.',
        experience: '3 years',
        icon: 'canoe',
      },
      {
        name: 'Covid Aware',
        description:
          'During these difficult times I understand the need to work in bubbles with children to minimise covid exposure and keep everyone safe.',
        experience: '3 years',
        icon: 'mask',
      },
      {
        name: 'Service',
        description:
          'I have great communication skills that I put to work when talking with parents or instructing out on the water.',
        experience: '3 years',
        icon: 'speech-bubble',
      },
    ],
  },
}
